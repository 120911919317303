.carousel-banner {
    background-color: #F5FAFE;
    width: 100%;
    padding: 30px;
    position: relative;

    &__btn-close{
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1;
        cursor: pointer;

        background-color: #F5FAFE;
        padding: 19px;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.625rem;
        color: $black;
    }

    &__btn-close:hover{
        color: rgba($color: $black, $alpha: .5);
    }

    &__btn-close:focus> .anticon svg{
        fill: $primary-color;
    }

    .ant-carousel .slick-slider>.slick-arrow {
        width: 30px;
        height: 30px;
        background-color: rgba($primary-color, .5);

        &:after {
            position: absolute;
            background-size: 15px;
            top: 9px;
            left: 7px;
        }

        &:hover {
            background-color: $primary-color;
        }
    }

    &__item-carousel {
        width: 100%;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__icon {
        margin-bottom: 30px;

        circle {
            fill: $white;
        }
    }

    &__sub-text {
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        text-align: center;
    }

    &__text {
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    &__text-info {
        color: $text-content-color;
        margin: 20px 0;
    }

    &__text-info>p {
        padding: 12px 100px;
        color: $text-content-color;
        text-align: center;

        b {
            color: $text-primary-color;
        }
    }

    @media($max-md) {
        &__text-info>p {
            padding: 5px 0;
        }
    }

    &__text-info>.p-main {
        color: $black;
    }
}

div.pad-list_container:has(> div.carousel-banner) {
    display: flex;
}

.contact-us {
    color: $text-primary-color;
    cursor: pointer;
    margin: 0 5px;
    font-weight: 700;
}

// future list
.feature-list {
    background-color: $white;
    padding: 20px 40px;
    width: 100%;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        color: $primary-color;

        >.feature-list__title {
            color: $text-content-color;
        }
    }
}