.live-search {
  &_container {
    position: relative;
    label {
      font-weight: 600;
      margin-bottom: 10px;
      display: inline-block;
      padding-left: 3px;
    }
  }

  &_input-wrapper {
    height: 64px;
    padding-right: 198px;
    padding-left: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $input-color;
    border-radius: $border-radius-base;
    position: relative;
    transition: 0.15s ease;
    @media ($max-md) {
      padding-right: 5px;
    }
    &.filled {
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    &.scrolling {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
      z-index: 99;
    }
  }

  &_input {
    display: flex;
    align-items: center;
    width: 100%;
    .ant-input {
      border-radius: $border-radius-base;
      padding: 0;
      min-height: unset;
      transition-property: width;
      transition-duration: 0s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ant-input-affix-wrapper {
      background-color: $input-color;
      border-radius: $border-radius-base;
      padding: 0;
      padding-left: 0;
    }
    .ant-input-prefix {
      position: relative;
      padding-right: 15px;
      &::after {
        content: '';
        position: absolute;
        right: 0px;
        width: 1px;
        height: 20px;
        background-color: $secondary-color;
      }
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $primary-color;
        }
      }
    }
  }

  &_prefix {
    padding: 0 15px;
    text-align: center;
    display: block;
    position: relative;
    margin-right: 15px;
    transform: translateY(1px);
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $primary-color;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 20px;
      background-color: #848b92;
      transform: translateY(1px);
    }
  }

  &_button {
    position: absolute;
    right: 8px;
    min-height: 48px;
    top: 50%;
    transform: translateY(-50%) scale(0.7);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: 0.3s;
    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      transform: scale(1) translateY(-50%);
    }
  }

  &_tag-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: 0.12s ease-in;
  }

  &_tag {
    color: #fff;
    transition: 0.12s ease-in;
    border-radius: $border-radius-base;
    min-width: 48px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    animation: tag-in 0.3s;

    &.primary-tag {
      background-color: $text-green-color;
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    overflow-y: auto;
    max-height: 500px;
    & > .list {
      position: absolute;
      width: 100%;
      z-index: 5;
      box-shadow: 0 6px 7px 0 rgba(#000, 0.1);
      display: flex;
      flex-direction: column;
      max-height: 50vh;
      overflow-y: auto;
    }
    .ant-btn {
      background-color: $input-color;
      text-align: left;
      border-radius: 0px;
      border: none;
      padding: 0px 20px;
      max-width: unset;
      height: unset;
      border-top: 1px solid #cdd6df;
      display: flex;
      align-items: center;
      &:disabled {
        opacity: unset;
        color: unset;
      }
      & > span[role='img']:first-of-type {
        position: relative;
        padding-right: 15px;
        img {
          width: 20px;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0px;
          width: 1px;
          height: 20px;
          background-color: $secondary-color;
          top: calc(50% - 10px);
        }
      }
      & > span {
        &.pad_name {
          padding-left: 13px;
          font-weight: 500;
        }
        &.pad_address {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
      &:hover {
        color: unset;
        background-color: #fff;
      }
    }
  }
}

@keyframes tag-in {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
