.survey-pad__view__upload-card {
  width: 100%;
  max-width: 280px;
}

.upload-card {
  &__title {
    color: #000;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 18px;
  }

  &__body {
    width: 280px;
    height: 204px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 12px;
    cursor: pointer;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #f4f8fb;
    border: 1px solid #dee4ea;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .upload-placeholder-icon {
      margin-bottom: 15px;
      display: block;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .upload-placeholder-text {
      font-size: 16px;
      color: #3e464e;
      
      @media ($max-md) {
        font-size: 14px;
      }

    }
  }

  &__footer {
    &.hidden {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .ant-btn {
      min-height: unset;
      width: unset;
      min-width: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        margin-right: 8px;
      }
    }
  }
}

.survey-pad-view__uploads {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  gap: 32px;
  justify-content: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1265px) {
  .survey-pad-view__uploads {
    grid-template-columns: repeat(2, 280px);
  }
}

@media only screen and (max-width: 768px) {
  .survey-pad-view__uploads {
    grid-template-columns: repeat(1, 280px);
  }
}
