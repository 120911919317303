.site-view_slider {
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-radius: $border-radius-base;
  .slider-slide {
    .image {
      height: 322px;
      overflow: hidden;
      border-radius: $border-radius-base;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
  }
}

.site-view {
  min-height: 540px;
  &_content {
    margin-top: 70px;
    margin-bottom: 24px;
  }
  &_title {
    font-size: 36px;
    margin-bottom: 50px;
  }
  &_information {
    .contact-inf {
      .data {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon {
        line-height: 1;
        margin-right: 10px;
        img {
          max-width: 20px;
        }
      }
    }
  }
  &_notes,
  &_establishments,
  &_tags {
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }
  &_rating {
    margin-top: 90px;
  }
  &_address {
    width: 100%;
    margin: 40px 0 50px;
    .address-line {
      .data {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .icon {
        line-height: 1;
        margin-right: 10px;
      }
    }
  }
  &_establishments {
    margin: 40px 0 35px;
  }
  &_tags {
    .tags-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
  &_extra-actions {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn {
      max-width: unset;
    }
  }
  &_confirm-modal {
    .ant-btn {
      min-height: unset;
      width: unset;
      max-width: unset;
    }
  }
}

.pad-edit_container {
  position: relative;
}