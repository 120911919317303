.country-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.select-country,
.select-state {
  transition-property: width, opacity;
  transition-duration: 0.2s;
}

.select-country {
  width: 100%;
  transition-delay: 0.05s;
}

.select-state {
  width: 0%;
  opacity: 0;
}

.country-selector.is-us {
  .select-country {
    width: calc(50% - 6px);
    transition-delay: 0s;
  }
  .select-state {
    width: calc(50% - 6px);
    opacity: 1;
  }
}
