.surveys-page {
    padding: 50px 0 70px;

    .section-title {
        margin-bottom: 75px;
    }

    .carousel-banner {
        margin-bottom: 50px;
    }

    @media ($max-lg) {
       .section-title {
            margin-bottom: 30px;
        } 
        
    }
    @media ($max-md) {
        .carousel-banner__sub-text {
            font-size: 16px;
            line-height: 24px;
        }
        .carousel-banner__text {
            font-size: 20px;
            line-height: 30px;
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

}

.mySurveys-space {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}