h1,
h2,
h3,
h4,
h5,
h6,
[class^='v1-h'],
[class*='v1-h'] {
  font-weight: 700;
}

h1,
.v1-h1 {
  font-size: 24px;
}
h2,
.v1-h2 {
  font-size: 18px;
}
h3,
.v-1h3 {
  font-size: 16px;
}
h4,
.v1-h4 {
  font-size: 13px;
}
h5,
.v1-h5 {
  font-size: 10px;
}
h6,
.v1-h6 {
  font-size: 8px;
}
