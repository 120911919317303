.pad-quick-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 20px;
  z-index: 10;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -1px 4px;
  border-top: 2px solid #b51452;
  font-weight: 400;
}

.pad-quick-actions p {
  font-size: 18px;
}

.pad-quick-actions .ant-btn {
  min-height: 36px;
}

.pad-quick-actions {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  &.enter-active {
    animation-name: alert-apperance;
  }

  &.exit-active {
    animation-name: alert-apperance;
    animation-direction: reverse;
  }
}

@keyframes alert-apperance {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.show-more-btn {
  min-height: 15px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0;
}

.button-link .ant-btn, .button-link.ant-btn {
  font-size: 14px;
  min-height: 40px;
  max-width: 180px;
  
  &.large {
    max-width: 280px;
  }
}