.surveys-item{
    width: 100%;
    &__content{
        background-color: $card-color;
        max-width: 1000px;
        margin: 40px auto;
        color: $text-content-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__content-info{
        padding: 20px 20px 20px 40px;
        @media($max-sm) {
            padding: 20px 20px 20px 20px;
        }
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content-img{
        max-width: 100%;
        max-height: 290px;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;

        img{
            max-width: 100%;
            min-height: 310px;
            object-fit: cover;
        }
    }

    &__info-meta{
        width: 100%;
        > div{
            margin-right: 20px;
            .pad-main_meta-category{
                img{
                    width: 16px;
                    height: auto;
                }
            }
        }
    }

    &__info-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .pad-main_data{
            margin: 10px 0;
            width: 100%;
            p{
                margin: 0;
            }
        }
        .pad-main_progress{
            width: 100%;
        }
    }
    &__btn-row{
        padding: 20px 0 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        & > .pad-survey-btn{
            margin-top: 10px;
        }

        @media ($max-lg) {
            flex-direction: row;
            padding: 20px 0 0 0;
            & > .pad-survey-btn {
                margin: 0;
            }
        }

        @media ($max-xs) {
            flex-direction: column;
            padding: 20px 0 0 0;
            & > .pad-survey-btn {
                margin: 10px 0;
            }
        }
    }
}

.pad-survey-btn.red{
    border-color: $primary-color;
    color: $primary-color;
}

@media($max-sm) {
    .pad-survey-btn{
        width: 100%;
        margin: 10px 0;
    } 
}
