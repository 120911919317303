.image-slider_modal {
  .slider-slide {
    .image {
      height: 322px;
      overflow: hidden;
      border-radius: $border-radius-base;

      img {
        max-width: 465px;
      }
    }
  }
}

.image-slider_modal {
  width: 85vw !important;
  max-width: 1160px;

  .ant-carousel {
    height: 60vh;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 30px;
  }

  .slider-slide {
    .image {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 20px 20px 15px;
    .ant-btn {
      width: unset;
    }
  }
}
