.steps-custom-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 60px;
  margin-top: 77px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 76%;
    height: 2px;
    background-color: #e5e5e5;
  }
  .steps-dot {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    background-color: #c4c4c4;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: background-color 0.2s ease-in-out;
    &::after {
      position: absolute;
      content: attr(data-title);
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      top: -52px;
      font-size: 16px;
    }
    &.progress {
      background: $primary-color;
      transition-delay: 0.1s;
      &::after {
        color: $text-content-color;
        font-weight: 600;
      }
    }
    &.waiting {
      &::after {
        color: $text-content-color;
      }
    }
    &.finished {
      border: none !important;
      background-image: url(../../../assets/icons/step-check.svg);
      background-size: 20px;
      background-position: 50% 50%;
      width: 20px;
      height: 20px;
      &::after {
        color: $text-light-color;
      }
    }
  }

  .steps-dot_mobile-text {
    display: none;
    position: absolute;
    top: calc(100% + 20px);
    transition: 0.2s ease-in-out;
    opacity: 0;
    font-weight: 600;
    &.active {
      opacity: 1;
      transition-delay: 0.1s;
    }
  }

  @media (max-width: 820px) {
    .steps-dot {
      &::after {
        font-size: 14px;
        top: -40px;
      }
    }
  }

  @media (max-width: 680px) {
    margin-top: 50px;
    margin-bottom: 75px;
    .steps-dot {
      &::after {
        display: none;
      }
    }
    .steps-dot_mobile-text {
      display: block;
    }
  }
}
