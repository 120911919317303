@import '~antd/dist/antd.less';

// Variables overrides
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// Base
@primary-color: #b51452;
@border-radius-base: 4px;
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@font-size-base: 16px;

// Form
@form-vertical-label-padding: 0 0 10px;
@form-item-label-height: auto;

// Steps
@steps-dot-top: 6px;
@process-tail-color: #f0f0f0;
@steps-icon-font-size: 24px;
@steps-description-max-width: 160px;

// Rate
@rate-star-color: #ffa800;
@rate-star-bg: #dee4ea;
@rate-star-size: 16px;

// Tooltip
@tooltip-bg: #ffffff;
@tooltip-color: #333;
@tooltip-arrow-width: 10px;
@tooltip-distance: @tooltip-arrow-width - 5px;

// Divider
@divider-text-padding: 20px;
@divider-color: #dadada;


//Menu
@menu-item-color: #000000;
@menu-highlight-color: #000000;

.ant-menu-item-selected {
  font-weight: 600;
  border-bottom: 2px solid @primary-color !important;
}
.ant-menu-item:hover {
  border-bottom: 2px solid @primary-color !important;
}

.ant-menu-horizontal {
  border-bottom: none;
}

@media (max-width: 768px) {
  .ant-menu {
    font-size: 12px;
    .ant-menu-item {
      margin: 0 10px !important;
    }
  }
}