code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus,
input:focus,
button:focus {
  outline: none;
}

@import './headings.scss';
@import './icons.scss';

.large-container {
  width: calc(100% - 10px - 10px);
  max-width: 1200px;
  margin: 0 auto;
}

.small-container {
  width: calc(100% - 10px - 10px);
  max-width: 860px;
  margin: 0 auto;
}

.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-index-list, '1002');
}

span.mark {
  color: $primary-color;
  font-weight: 500;
}

.auto-size-btn {
  min-width: auto;
  min-height: auto;
  padding: 0;
  margin: 0;
}

.hide-medium {
  @media ($max-md) {
    display: none !important;
  }
}

.text-primary-color {
  color: $text-primary-color;
}

.text-content-color {
  color: $text-content-color;
}

.text-light-color {
  color: $text-light-color;
}

.text-green-color {
  color: $text-green-color;
}

.text-black-color {
  color: $text-black-color;
}

.text-error-color {
  color: $error-color;
}

.sub-label-text {
  color: $secondary-color;
  font-size: 13px;
  margin-bottom: 20px;
}

.sub-label-description {
  padding: 20px 20px 54px;
  font-size: 16px;
  background-color: $input-color;
  border-radius: $border-radius-base;
  margin-bottom: 24px;
}

.dms-input {
  &::placeholder {
    font-weight: initial;
  }
}

.truncate-text {
  display: flex;

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title-with-action {
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      transform: translateY(-1.5px);

      path {
        fill: $primary-color;
      }
    }

    &.bigger {
      svg {
        width: 20px;
      }
    }
  }
}

.ant-popover-inner-content {
  max-width: 420px;
}

.country-selector {
  .ant-select {
    max-width: 100%;
    text-align: left;
  }
}

.loading-bars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  display: block;
  height: 36px;
  width: 10px;
  background: #fff;
  margin: 0 12px;
  animation: scale 1.6s ease-in-out infinite;
  transform-origin: 50% 50%;
  box-shadow: 2px 2px 2px #b51452;
}

.bar.one {
  animation-delay: 1s;
}

.bar.two {
  animation-delay: 1.2s;
}

.bar.three {
  animation-delay: 1.3s;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.ant-modal-confirm-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: unset;
  margin-top: 24px;
}

// custom style when modal contains icon and title only
.ant-modal-confirm-body-centered {
  .ant-modal-confirm-body {
    display: flex;
    align-items: center;
  }
}

// scrollbar
.scrollbar-thin {
  >textarea::-webkit-scrollbar {
    width: 5px;
  }

  >textarea::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
  }
}

// map
.gm-style-mtc>button {
  @media ($max-md) {
    font-size: 12px !important;
  }
}

.mb-5px {
  margin-bottom: 10px;
}

.relative {
  position: relative;
}

.direction-relative {
  .image {
    position: relative;

    .direction {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      color: $primary-color;
    }
  }
}

.direction-relative-box {
  position: relative;

  div>.image {
    .direction {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      color: $primary-color;
    }
  }
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  .bar-step-title {
    margin-left: 5px;
  }
}

.confirm-modal_modal-survey {
  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 16px;
  }

  .ant-modal-confirm-content .confirm-modal .bar-step-title {
    font-size: 14px;

  }
}

.confirm-modal_modal-survey .ant-modal-content .ant-modal-confirm-body-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-modal-confirm-btns {
    button {
      width: 100%;
      min-width: 150px;
    }
  }
}

.text-center-md {
  @media ($max-md) {
    text-align: center;
  }
}

.spin-white {
  margin: 0 10px;

  .ant-spin-dot i {
    background-color: #fff;
  }
}

.pointer {
  cursor: pointer;
}

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}