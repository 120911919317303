.registration {
  padding: 30px 0;
  &-stepts {
    margin-bottom: 40px;
  }
}

// PERSONAL DETAILS
.personal-details {
  max-width: 500px;
  margin: 0 auto;
  &_inf {
    margin-bottom: 40px;
    text-align: center;
    .ant-form {
      text-align: left;
    }
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// CHOOSE ROLE
.choose-role {
  max-width: 384px;
  margin: 0 auto;
  .ant-btn {
    margin-top: 30px;
  }
  &_warning {
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 13px;
    font-weight: 500;
    p {
      margin-bottom: 16px;
    }
    .warnings {
      display: flex;
      img {
        width: 134px;
      }
      .app-store {
        margin-right: 10px;
        width: unset;
      }
    }
  }
}

// SEARCH PAD
.search-pad {
  max-width: 801px;
  margin: 0 auto;
  &_actions {
    padding-top: 50px;
  }
}

// TERMS
.accept-terms {
  max-width: 765px;
  margin: 0 auto;
  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .ant-checkbox-wrapper {
    font-size: 14px;
  }
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      &:not(:last-of-type) {
        margin-bottom: 36px;
      }
      margin-left: 0;
    }
  }
}

// LAST STEP
.last-step {
  h1 {
    color: $primary-color;
    margin-bottom: 39px;
  }
  p {
    margin-bottom: 30px;
  }
  .ant-btn {
    max-width: 243px;
  }
}

// PAD DETAILS
.pad-form {
  .ant-checkbox-group {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-right: 5px;
      margin-left: 0;
    }
  }
}

// REGISTRATION PAGE
.registration-page {
  display: block;
  width: 100%;
  min-height: 665px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/images/login-bg.svg');
  .ant-select .ant-select-selector {
    background: #fff !important;
  }
  .registration-page-content {
    padding: 80px 20px 30px;
    max-width: 540px;
    margin: 0 auto;
  }
  .ant-input-prefix,
  .ant-input-suffix,
  .ant-input {
    background-color: #ffffff !important;
    border-radius: $border-radius-base !important;
  }
  .flag-dropdown.open .selected-flag {
    background-color: #fff !important;
  }
  .react-tel-input {
    .selected-flag {
      background-color: #fff;
      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
  }
  .ant-input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset;
  }

  .ant-form-item-has-success {
    .ant-form-item-children-icon {
      right: 35px !important;
    }
  }
  .ant-form-item-has-error {
    .ant-form-item-children-icon {
      right: 35px !important;
    }
  }

  &.success {
    .registration-page-content {
      padding: 56px 30px 30px;
    }
    p,
    h1 {
      color: #ffffff;
    }
    p {
      font-size: 16px;
      text-align: center;
    }
    .ant-btn-primary {
      max-width: 248px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      color: $primary-color;
      border-color: #ffffff;
    }
  }

  .ant-select {
    max-width: unset;
  }

  .ant-form.ant-form-horizontal {
    overflow: hidden;
  }
}

// FORGOT PASSWORD
.forgot-pass-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 540px;
  background-image: url('../../../assets/images/login-bg.svg');

  .forgot-pass-content.success {
    text-align: center;
    max-width: 500px;
  }
  .success {
    &-image {
      margin-bottom: 10px;
      img {
        width: 45px;
      }
    }
    &-title {
      color: #f4f4f4;
      font-size: 24px;
    }
    &-descrp {
      font-size: 18px;
      color: rgb(236, 236, 236);
      a {
        color: rgb(236, 236, 236);
        text-decoration: underline;
      }
    }
  }
}

.forgot-pass-content {
  min-width: 400px;
  max-width: 400px;

  .form-title {
    color: #f4f4f4;
    margin-bottom: 20px;
  }

  .ant-btn {
    max-width: unset;
  }

  .forgot-blurb {
    max-width: 420px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    color: #f4f4f4;
  }
}

.content-image {
  margin-bottom: 24px;
  img {
    width: 40px;
  }
  &.error {
    animation: shakeX 0.5s 0.3s;
    img {
      border-radius: 50%;
      border: 2px solid #fff;
      padding: 8px;
      width: 50px;
    }
  }
  &.wave-eff {
    position: relative;
    width: 70px !important;
    height: 70px !important;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 0 auto 32px;
    img {
      padding: 8px;
      width: 70px;
      position: absolute;
      left: -5px;
      bottom: -5px;
    }
  }
}

// ACTIVATION PAGE
.activation-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 520px;
  background-image: url('../../../assets/images/login-bg.svg');
  .content-action {
    margin-top: 5px;
  }
  .content-descrp {
    max-width: 540px;
  }
  &_content {
    color: #f4f4f4;
    text-align: center;
    max-width: 360px;
    .activation-loader {
      position: absolute;
      background-color: #fff;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .form-title {
      color: inherit;
    }
  }
}

@media ($max-md) {
  .registration-page {
    .registration-page-content {
      padding: 80px 20px;
    }
  }
}
