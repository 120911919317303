$black: #000;
$white: #fff;

// Antd variables;
// These are only copies;
// Look in "/less/antd.less" to override theme variables;
$primary-color: #b51452;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$normal-color: #d9d9d9;

// Custom colors
$orange-color: #ffa800;
$primary-color: #b51452;
$input-color: #e7edf3;
$secondary-color: #848b92;
$alto: #dadada;

// Text colors
$text-primary-color: $primary-color;
$text-content-color: #3e464e;
$text-light-color: $secondary-color;
$text-green-color: #27ae60;
$text-black-color: $black;

//card colors
$card-color: #f4f8fb;
