.banner {
  background-color: #F5FAFE;
  padding: 19px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.625rem;
  color: $black;

  &__button-close:hover {
    color: rgba($color: $black, $alpha: .5);
  }

  &__button-close:focus>.anticon svg {
    fill: $primary-color;
  }

  &_inner {
    width: 100%;
    text-align: center;
  }
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

@media ($max-md) {
  .banner {
    .banner_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      line-height: 24px;

      button {
        font-size: 12px;
      }
    }
  }
}