.survey-greeting {
  &__header {
    text-align: center;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 24px;

    @media ($max-md) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 34px;

    @media ($max-md) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 35px 0 38px;
    max-width: 759px;
    p {
      margin-bottom: 10px;
    }

    @media ($max-md) {
      font-size: 14px;
      line-height: 22px;
      padding: 0 10px ;
    }
  }
}
