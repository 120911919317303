.progress-box {
    width: 100%;

    &__showInfo {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #424242;
        margin-top: 26px;
    }
}

.width-full {
    width: 100%;

    .ant-progress-steps {
        width: 100%;
    }

    .ant-progress-steps-item {
        display: flex;
        flex: 1;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.2);
    }
}