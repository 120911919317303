.ant-tooltip.custom-tooltip {
  min-width: 295px;
  .ant-form-item {
    margin-bottom: 3px;
  }
  .ant-tooltip-inner {
    padding: 15px 15px 5px;
    border: $base-border;
  }
  .ant-tooltip-arrow {
    overflow: visible;
    right: 30px;
  }
  .ant-tooltip-arrow-content {
    border-left: $base-border;
    border-top: $base-border;
  }
  .ant-btn {
    font-size: 12px;
    font-weight: bold;
    color: $primary-color;
    padding-left: 2px;
    width: unset;
  }
  textarea.ant-input {
    background-color: #ffffff;
    border-color: $border-color;
    font-size: 12px;
    color: $text-content-color;
    padding: 12px;
    min-height: 103px;
    resize: none;
  }
  input.ant-input {
    font-size: 15px;
    line-height: 1;
    min-height: unset;
    padding: 10px 16px;
  }
}

@media ($max-sm) {
  .ant-tooltip {
    min-width: 250px;
  }
}
