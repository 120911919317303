// BUTTONS
.ant-btn {
  font-size: 16px;
  min-height: 56px;
  max-width: 198px;
  width: 100%;
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  &.secondary-btn {
    border-color: $secondary-color;
    background: $secondary-color;
    color: #fff;
    &:hover {
      border-color: $secondary-color;
      background: lighten($secondary-color, 5%);
    }
  }
  &.ant-btn-text {
    min-width: unset;
    min-height: unset;
    &:hover {
      background-color: transparent;
    }
  }
  &.ant-btn-primary:disabled {
    background-color: $primary-color;
    color: #fff;
    border-color: $primary-color;
  }
  &.ant-btn-lg {
    width: 100%;
    max-width: 384px;
  }
  &.outline-btn {
    border: 2px solid $primary-color;
    min-height: 48px;
    max-width: 128px;
    font-size: 14px;
    color: $black;
    @media ($max-md) {
      min-height: 38px;
      font-size: 12px;
    }
  }
  &.ant-btn-reseted {
    min-width: unset;
    min-height: unset;
    padding: 0;
  }
}

.ant-popover-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-btn {
    min-height: 30px;
    max-width: 104px;
    border-radius: 3px;
  }
}

.ant-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .ant-btn {
    border-radius: $border-radius-base;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}

// INPUTS
.ant-select,
.ant-input,
.ant-btn,
.ant-input-number {
  font-size: 16px;
  min-height: 56px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 16px;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 56px;
}

.ant-input,
.ant-input-number {
  background-color: $input-color !important;
  border-color: $input-color;
  padding: 16px;
  line-height: 1;
  box-shadow: unset !important;
  .ant-form-item-children-icon {
    display: none !important;
  }
  &:hover,
  &:focus {
    border-color: $input-color;
    box-shadow: unset;
  }
  border-radius: $border-radius-base;
  &::placeholder {
    color: #818181;
  }
  color: #424242;
  &.ant-input-lg {
    max-width: 100%;
  }
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-input {
  height: unset;
  padding: 0;
}

.ant-input-affix-wrapper {
  padding: 0;
  border: none;
  border-color: $input-color;
  max-height: 56px;
  max-width: 100%;
  box-shadow: unset;
  background-color: $input-color !important;
  box-shadow: unset !important;
  .ant-input-prefix {
    padding-left: 10px;
  }
  & > input.ant-input {
    border-radius: $border-radius-base;
    padding-right: 5px;
    padding: 16px;
  }
  .ant-input-prefix {
    background-color: $input-color;
    margin-right: 0;
    padding-left: 15px;
    border-radius: $border-radius-base 0 0 $border-radius-base;
  }
  .ant-input-suffix {
    background-color: $input-color;
    margin-left: 0;
    padding-right: 15px;
    border-radius: 0 $border-radius-base $border-radius-base 0;
  }
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $input-color !important;
}

.ant-select {
  border-color: $input-color !important;
  background: $input-color;
  border-radius: $border-radius-base;
  width: 100%;
  overflow: hidden;
  max-width: 368px;
  &:hover {
    .ant-select-selector {
      border-color: $input-color !important;
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: unset !important;
    }
  }
  .ant-select-selector {
    padding: 16px !important;
    background: $input-color !important;
    min-height: inherit;
    border-color: $input-color !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

textarea.ant-input {
  min-height: 140px;
}

.ant-select-multiple {
  padding: 0px;
  max-width: 100%;
  .ant-select-selector {
    padding: 8px 6px 8px 11px !important;
  }
  .ant-select-clear {
    background: $input-color;
  }
  .ant-select-selection-item {
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffa800;
    padding-right: 9px;
    .ant-select-selection-item-remove {
      font-size: 14px;
      padding-left: 6px;
    }
  }
  .ant-select-selection-search {
    margin-inline-start: 0px;
  }
}

// SIMPLE TAGS
.ant-tag.ant-tag-orange {
  min-height: 34px;
  background-color: #ffa800;
  border-color: #ffa800;
  line-height: 32px;
  color: #1a1f24;
  font-size: 14px;
  border-radius: 2px 0 0 2px;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  padding: 0px 26px 0px 9px;
  margin-bottom: 10px;
}

// FORM ITEM
.ant-form-item-label > label {
  font-weight: 700;
}

// RADIO FIELD //
.custom-radio-wrapper {
  display: block;
  font-size: unset;
  .custom-radio-field:not(:first-of-type) {
    margin-top: 30px;
  }
}
.custom-radio-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 12px 16px;
  background-color: $input-color;
  border-radius: $border-radius-base;
  min-height: 56px;
  span:last-of-type {
    line-height: 1;
  }
  .ant-radio {
    top: 0;
    height: 24px;
    width: 24px;
    .ant-radio-inner {
      width: 24px;
      height: 24px;
      border: none;
      box-shadow: none !important;
      &::after {
        background-size: 24px;
        background-color: transparent;
        background-image: url('../../../assets/icons/check.svg');
        transform: scale(0.2);
        opacity: 0;
        transition: 0.1s ease-in-out;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
      }
    }
  }
  &.ant-radio-wrapper-checked {
    .ant-radio .ant-radio-inner::after {
      transform: scale(1);
      opacity: 1;
      background-color: $input-color;
    }
  }
}

// PHONE INPUT
.phone-input {
  &_wrapper {
    position: relative;
    width: 100%;
    input {
      padding-left: 56px;
      font-size: 16px;
    }
    .react-tel-input {
      .form-control {
        width: 100%;
        border-color: $input-color;
      }
      .country-list {
        border-radius: 4px;
      }
      .flag-dropdown {
        border: 1px solid #cacaca;
        border-right: 0;
      }
      input {
        font-size: 16px;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
          'Noto Color Emoji';
      }
      .flag-dropdown.open {
        border-radius: 4px;
        .selected-flag {
          background-color: $input-color;
          border-radius: 4px;
        }
      }
      .selected-flag {
        width: 46px;
        padding: 0 0px 0 15px;
        background-color: $input-color;
        &:hover,
        &:focus {
          background-color: $input-color;
        }
      }
    }
  }
}

.survey-container {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-bottom: 5px;
    }
  }

  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border-color: $input-color;
    background-color: $input-color;
    &::after {
      width: 11px;
      height: 11px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $input-color;
    }
    .ant-radio-inner::after {
      transform: scale(1) translate(-50%, -50%);
    }
    & + * {
      font-weight: 500;
    }
  }

  span.ant-radio + * {
    transform: translateY(3px);
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100%;
}
