.my-sites-page {
  padding: 50px 0 70px;

  .pad-list_container {
    margin-top: 75px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 40px;
    margin-bottom: 50px;

    &.managing {
      margin-top: 50px;
    }

    .search-pad {
      width: 100%;
      max-width: 920px;
    }
  }

  .subtitle {
    margin-bottom: 8px;
    color: #424242;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
}

.create-pad {
  padding: 0 0 60px;
}

.site-reviews {
  padding: 40px 0 60px;

  &_overall {
    &>h1 {
      margin-bottom: 35px;
    }

    margin-bottom: 34px;
  }

  &_interest {

    h3,
    h1 {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 10px;
    }

    text-align: right;
  }

  &_list {
    margin-top: 48px;

    .section-title {
      margin-bottom: 35px;
    }

    .list-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 40px;
    }
  }

  &_sort {
    background-color: $input-color;
    width: 280px;
    min-height: 56px;
    padding: 12px 20px;
    max-width: 100%;
    border-radius: $border-radius-base;
    transition: 0.12s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 500;

    &.full-width-md {
      @media($max-md) {
        width: 100%;
      }
    }
  }

  &_pagination {
    margin: 0 0 64px;

    .ant-btn {
      max-width: 163px;
      max-height: 48px;
      font-weight: 500;
    }
  }

  .small-container {
    max-width: 1020px;
  }

  .rate-label {
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.my-account {
  padding: 50px 0;
  min-height: calc(100vh - 99px);

  .ant-select {
    max-width: unset;
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  &_content {
    margin-top: 30px;
  }

  &_personal-details,
  &_change-pass {
    margin-bottom: 35px;
  }

  .my-accout-google {
    display: inline-block;
    max-width: unset;
    width: unset;

    img {
      width: 20px;
    }
  }

  &_row-head {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 0;
    }

    button {
      min-height: unset;
    }
  }

  .ant-form-item-control-input {
    max-width: 417px;
  }

  &_user-info {
    min-height: 56px;
    align-items: center;
    justify-content: flex-start;
    display: flex;

    .icon {
      color: #707070;
      margin-right: 12px;
    }
  }

  &_notification {
    font-size: 14px;

    &>p {
      margin-bottom: 20px;
    }

    .ant-checkbox-wrapper {
      font-size: 14px;
      width: 100%;
    }

    .ant-form-item-control-input {
      max-width: unset;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .my-account_user-info {
      min-height: 40px;
    }
  }

  &_subscription {
    background-color: $input-color;
    border-radius: $border-radius-base;
    padding: 30px 20px;

    h1 {
      font-size: 24px;
      margin-bottom: 22px;
      color: $text-green-color;
    }

    .pay-button {
      padding: 8px 24px;
      width: 100%;
      display: block;
      margin: 30px 0 10px;
      background-color: #f4bf37;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 47px;
      border: none;
      position: relative;
      transition: 0.2s;
      cursor: pointer;

      &:active {
        transform: scale(0.95);
      }

      .loading-icon {
        display: none;
        margin-right: 14px;
      }

      .text {
        color: rgb(43, 43, 43);
        font-weight: 600;
      }

      .icon {
        margin-right: 8px;
        transition: 0.2s;

        img {
          max-width: 60px;
          width: 60px;
        }
      }

      &[data-loading='true'] {
        pointer-events: none;
        opacity: 0.7;

        .text {
          display: none;
        }

        .loading-icon {
          display: block;
        }
      }

      &[data-disabled='true'],
      &:disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .user-meta {
      margin-bottom: 22px;
    }

    .user-meta_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-black-color;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .value {
        font-weight: 500;
      }
    }

    .description {
      color: $text-content-color;
    }
  }

  .ant-btn-link.custom {
    color: $text-black-color;
    font-weight: 500;
  }
}

@media($max-md) {
  .my-account {

    &_personal-details,
    &_change-pass,
    &_notification {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &_change-pass_inner {
      width: 100%;
    }

    &_personal-details>.ant-form-item,
    &_change-pass_inner>.ant-form-item {
      width: 100%;

      >.ant-form-item-control>div {
        max-width: 100%;
      }
    }

    &_user-info {
      justify-content: center;
      justify-content: flex-start;

      >.text {
        overflow-wrap: anywhere;
      }
    }
  }
}