.footer-container {
  font-size: 14px;

  .social-media-block {
    padding: 30px;
    border-top: $base-border;
    border-bottom: $base-border;

    p {
      margin-bottom: 30px;
    }

    a {
      margin: 0 35px;
    }
  }

  .contact-block {
    padding: 40px 30px;
    & > div {
      padding: 0 10px;
    }
  }

  p {
    margin-bottom: 6px;
  }
}

@media ($max-lg) {
  .footer-container {
    font-size: 12px;
  }
}

@media ($max-md) {
  .footer-container {
    .social-media-block {
      padding: 15px 10px;
      p {
        margin-bottom: 15px;
      }
      a {
        margin: 0 15px;
      }
    }
    .contact-block {
      flex-direction: column;
      padding: 15px 10px;

      & > div {
        padding: 10px 10px;

        &:not(:last-child):after {
          content: " ";
          display: block;
          position: relative;
          height: 1px;
          border-bottom: $base-border;
          width: 140px;
          top: 10px;
          margin: 0 auto;
        }
      }
    }
  }
}