.timeline-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &>.flex-column {
    width: 20%;
  }

  .approve-btn {
    width: 100%;
    margin: 10px 0 0;
    min-height: 40px;
  }

  @media(max-width: 768px) {
    flex-direction: column;

    &>.d-flex.flex-column {
      width: 100%;
      flex-direction: row !important;

      b {
        margin-right: 10px;
      }

      .approve-btn {
        margin: 10px 0;
      }
    }

    &>.d-flex.flex-column.button-row {
      flex-direction: column !important;
    }

    & .review-inner_user {
      align-items: flex-start;
    }

    & .user-meta {
      text-align: initial;
    }
  }

  @media(max-width: 576px) {
    .approve-btn {
      width: 100%;
      max-width: 100%;
    }
  }

  padding: 1rem 1.5rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
}

.text-color-primary {
  color: #b51452;
}

.h-center {
  height: 130px;
  justify-content: center;

  @media(max-width: 768px) {
    height: auto;
  }
}

.modal-action-btns {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  &>button {
    min-height: 40px;
  }
}

.m-0 .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0;
}

.modal-box-approve {
  .ant-modal-body {
    padding: 2rem;
  }
  .ant-modal-footer{
    text-align: center;
  }
}

.description-m {
  margin-top: 73px;

  @media(max-width: 768px) {
    margin-top: 100px;
  }
}
