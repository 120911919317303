.login-page {
  display: block;
  width: 100%;
  min-height: 665px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/images/login-bg.svg');
  .login-page-content {
    padding: 158px 20px 50px;
    max-width: 1037px;
    margin: 0 auto;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 30px;
  }
  .left-col {
    padding-right: 88px;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
      display: block;
      width: 1px;
      background-color: #e5e5e5;
      height: 280px;
    }
  }

  .right-col {
    padding-left: 88px;
  }

  .ant-input-prefix,
  .ant-input-suffix,
  .ant-input {
    background-color: #ffffff !important;
    border-radius: $border-radius-base !important;
  }

  .ant-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }

  .addText {
    color: #ffffff;
  }

  .orTxt {
    color: #ffffff;
    padding-top: 25px;
    padding-bottom: 25px;
    max-width: 248px;
    text-align: center;
  }
}

.google-login-button {
  background-color: transparent !important;
  display: block !important;
  color: initial !important;
  box-shadow: initial !important;
  font-size: initial !important;
  opacity: 1 !important;
  max-width: 248px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  & > span {
    padding: 0 !important;
    display: block;
  }

  .ant-btn {
    background: #fff;
    border-color: #fff;
    max-width: unset;
    color: #333;
    img {
      max-width: 20px;
    }
  }
}

@media ($max-xl) {
  .login-page {
    .left-col {
      padding-right: 30px;
    }

    .right-col {
      padding-left: 30px;
    }
  }
}

@media ($max-lg) {
  .login-page {
    h2 {
      font-size: 20px;
    }
  }
}

@media ($max-md) {
  .login-page {
    .login-page-content {
      padding: 80px 20px;
    }
    h2 {
      padding-bottom: 10px;
    }
    .left-col {
      padding-right: 0;
      border-right: none;
      margin-bottom: 30px;
      &:after {
        right: initial;
        top: initial;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #e5e5e5;
        height: 1px;
      }
      .ant-btn-link {
        margin-top: 20px;
      }
    }

    .right-col {
      padding-left: 0;
    }
    .orTxt {
      max-width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .ant-btn {
      max-width: 100% !important;
    }
  }
}
