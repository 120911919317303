.search-location {
  max-width: initial;
  .ant-select-selector {
    padding: 0 !important;
  }
  textarea {
    min-height: initial;
  }
  .ant-select-selection-placeholder {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
