.review {
  &-inner {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 30px;

    &_user {
      grid-column: 1 / 3;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &_content {
      grid-column: 3 / 10;
    }

    &_report {
      position: absolute;
      bottom: 25px;
      right: 20px;

      .ant-btn::before {
        background: unset;
      }

      @media($max-sm) {
        position: initial;
      }
    }

    @media($max-xs) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .review-inner_user {
    .user-image {
      margin-bottom: 10px;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
      }

      span {
        width: 56px;
        height: 56px;
        background-color: $input-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .user-meta {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;

      .name {
        text-transform: capitalize;
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
      }

      .total-review {
        font-weight: 500;
      }
    }
  }

  .review-inner_content {
    background-color: $input-color;
    padding: 30px 25px;
    border-radius: $border-radius-base;
    font-size: 14px;
    position: relative;

    @media($max-sm) {
      padding: 15px 15px;
    }

    &::before {
      content: '';
      background-color: $input-color;
      clip-path: polygon(100% 0, 0 45%, 100% 43%);
      width: 30px;
      height: 70px;
      position: absolute;
      top: 30px;
      right: calc(100% - 2px);
    }

    .content-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      font-weight: 500;

      @media($max-sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .visits {
      display: flex;
      align-items: center;
      justify-content: center;

      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin-left: 10px;

        img {
          margin-right: 4px;
        }

        &.true {
          color: #27ae60;
        }

        &.false {
          color: #e01f1f;
        }
      }
    }

    .content-comment {
      margin-bottom: 30px;
    }

    .content-attachments {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 25px;
    }

    .attach-item {
      max-width: 110px;
      max-height: 80px;
      border-radius: $border-radius-base;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      img {
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}