.loadingBar-root {
  position: fixed;
  width: 100%;
  height: 5px;
  overflow-x: hidden; }

.loadingBar-root_line {
  position: absolute;
  opacity: 0.4;
  background: $primary-color;
  width: 150%;
  height: 5px; }


%subline {
  position: absolute;
  background: $primary-color;
  height: 5px; }

.loadingBar-root_inc {
  @extend %subline;
  animation: increase 2s infinite; }

.loadingBar-root_dec {
  @extend %subline;
  animation: decrease 2s 0.5s infinite; }


@keyframes increase {
  from {
    left: -5%;
    width: 5%; }
  to {
    left: 130%;
    width: 100%; } }

@keyframes decrease {
  from {
    left: -80%;
    width: 80%; }
  to {
    left: 110%;
    width: 10%; } }

