// Styles for sticky header

.headers-container {
  height: 104px;
  transition: height 0.7s ease-in-out;
  background: #ffffff;

  &.hasHeaderL2 {
    height: 234px;
    transition: height 0.7s ease-in-out;
  }

  .header-container {
    &.header-container-l1 {
      height: 104px;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1000;
      transition: height 0.7s ease-in-out;
    }
    &.header-container-l2 {
      height: 130px;
      position: fixed;
      top: 104px;
      right: 0;
      left: 0;
      z-index: 1000;
      transition: all 0.7s ease-in-out;
    }
  }

  &.isSticky {
    height: 55px;
    &.hasHeaderL2 {
      height: 110px;
    }

    .header-container {
      &.header-container-l1 {
        height: 55px;
      }
      &.header-container-l2 {
        height: 55px;
        top: 55px;
      }
    }
  }
}

// General styles

.container{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.header-container {
  color: #000;

  .users-name {
    font-size: 16px;
    padding: 0 18px;
    white-space: nowrap;
  }

  .custom-icon-dark-logo-small {
    display: none;
  }

  .ant-menu {
    background: transparent;
  }

  &.logged {
    & > div {
      width: calc(100% - 10px - 10px);
    }
  }

  &.header-container-l1 {
    background: #ffffff;
    padding: 24px 0 26px;
    .ant-menu {
      position: absolute;
      left: 37%;
    }

    &.hasBorder {
      border-bottom: 1px solid #e5e5e5;
    }
    & > div {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &.header-container-l2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $input-color;
    height: 99px;
    transition: .2s ease-in;
    &.sticky {
      position: sticky;
      top: -1px;
      z-index: 99;
      height: 89px;
      box-shadow: 0 4px 10px 0 rgba(#000, 0.2);
    }
    .default-link {
      button {
        font-weight: 600;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    button {
      min-height: 46px;
    }
    .ant-btn-link {
      color: inherit;
    }
    button {
      min-width: 128px;
    }
    .header-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        max-width: unset;
        min-width: unset;
      }
    }
    @media($max-sm) {
      height: auto;
      padding: 10px 0;
      .content {
        flex-wrap: wrap;
        justify-content: center;
        & > div {
          margin-bottom: 10px;
        }
        .header-actions{
          width: 100%;
        }
        & > .header-actions {
          & > button {
            max-width: min-content;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media ($max-lg) {
  .header-container {
    .users-name {
      display: none;
    }
  }
}

@media ($max-md) {
  // Styles for sticky header

  .headers-container {
    height: 55px;
    transition: height 0.7s ease-in-out;

    &.hasHeaderL2 {
      height: 110px;
      transition: height 0.7s ease-in-out;
    }

    .header-container {
      &.header-container-l1 {
        height: 55px;
        transition: height 0.7s ease-in-out;
      }
      &.header-container-l2 {
        height: 55px;
        top: 55px;
      }
    }
  }

  // General styles

  .header-container {
    .users-name {
      display: none;
    }

    &.logged {
      .custom-icon-dark-logo {
        display: none;
      }
      .custom-icon-dark-logo-small {
        display: block;
      }
    }

    &.header-container-l1 {
      .ant-menu {
        position: initial;

        .ant-menu-item > .ant-badge {
          font-size: 12px;
        }
        .ant-menu-item > .ant-badge > .ant-badge-count {
          font-size: 10px;
        }
      }
    }

    &.header-container-l2 {
      .initialPosition {
        .ant-menu {
          position: initial;
        }
      }

      .ant-btn {
        min-height: 38px;
      }
      .ant-btn.ant-btn-primary {
        font-size: 12px;
        // max-width: 58px;
      }
      .ant-btn.ant-btn-link {
        font-size: 12px;
      }
      .action-buttons {
        min-width: 100px;
      }
    }
  }
}
