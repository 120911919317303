.ant-upload.ant-upload-select-picture-card {
  width: 160px;
  height: 108px;
  margin: 0;
  border-style: solid;
  border-width: 2px;
}

.ant-upload-list-picture-card-container {
  margin: 0;
  width: 160px !important;
  height: unset;

  &:hover {
    .ant-btn {
      opacity: 1;
    }
  }
  .ant-btn {
    min-width: unset;
    min-height: unset;
    padding: 0;
    color: $primary-color;
    font-weight: 600;
    width: 100%;
    font-size: 16px;
    opacity: 0;
  }
  .picture-image-container {
    &.is-dragging {
      .picture-image-preview {
        border: 2px dashed #c0c0c0;
        img {
          opacity: 0;
        }
      }
    }
  }
  .picture-image-preview {
    width: 160px;
    height: 108px;
    background-color: #f4f4f4;
    border: 1px solid #e4e4e4;;
    overflow: hidden;
    border-radius: $border-radius-base;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
    &__btn {
      cursor: pointer;
    }
    &__error {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      color: $error-color;
      text-align: center;
      font-weight: 500;
    }
  }
}

.ant-upload-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
  gap: 10px;
  justify-content: start;
  &::before {
    display: unset;
    content: unset;
  }
}
