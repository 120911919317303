.rate-inline-container {
  width: 100%;
  ul.ant-rate {
    li.ant-rate-star:not(:last-child) {
      margin-right: 5px;
    }
  }
  .rate-and-title-element {
    &:not(:last-child) {
      margin-right: 65px;
    }
    .rate-value {
      margin-left: 7px;
      width: auto;
      min-width: auto;
    }
  }
}

.rate-block-container {
  background: #f4f8fb;
  padding: 25px 29px;
  border-radius: $border-radius-base;

  ul.ant-rate {
    li.ant-rate-star:not(:last-child) {
      margin-right: 15px;
    }
  }

  .rate-and-title-element:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.rate-and-title-element {
  .rate-value {
    color: $secondary-color;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    width: 50px;
    min-width: 50px;
    text-align: right;
  }
  .rate-label {
    color: $text-black-color;
    font-size: 16px;
  }
}

@media ($max-lg) {
  .rate-inline-container {
    .rate-and-title-element {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
}

@media ($max-md) {
  .rate-inline-container {
    flex-direction: column;
    .rate-and-title-element {
      &:not(:last-child) {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }
}

@media ($max-sm) {
  .rate-block-container {
    .rate-and-title-element {
      flex-direction: column;
      align-items: flex-start !important;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}