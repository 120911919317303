.ant-carousel {
  position: relative;
  .slick-prev {
    left: 5px;
    &::after {
      background-image: url(../../../assets/icons/arrow-left.svg);
    }
  }
  .slick-next {
    right: 5px;
    &::after {
      background-image: url(../../../assets/icons/arrow-right.svg);
    }
  }
  .slick-arrow {
    z-index: 9;
    font-size: 0;
    width: 40px;
    height: 40px;
    background-color: rgba(#000, 0.7);
    border-radius: 50%;
    &:hover,
    &:focus {
      background-color: rgba(#000, 0.7);
    }
    &::after {
      content: '';
      position: absolute;
      background-size: 24px;
      width: 100%;
      height: 100%;
      top: 11px;
      left: 7px;
      background-repeat: no-repeat;
    }
  }
  .slick-dots-bottom {
    z-index: 999;
    top: unset;
    bottom: unset;
    top: calc(100% + 45px);
    li {
      width: 6px;
      height: 6px;
      margin: 0 5px;
      button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        opacity: 1;
        background: #c4c4c4;
      }
      &.slick-active {
        width: 6px;
        button {
          background: $primary-color !important;
        }
      }
    }
  }
}
