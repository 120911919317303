.survey-steps-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.survey-bar-step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.bar-step-icon {
  margin-bottom: 8px;

  &.pad-view {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  svg {
    width: 32px;
    height: 32px;
  }
}

.bar-step-title {
  font-size: 18px;
  line-height: 32px;
  color: #3e464e;
}

.bar-step-status {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: none;
  }
}

.survey-bar-step.active {
  .bar-step-status {
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #b51452;
    }

    svg {
      display: none;
    }
  }
}

.survey-bar-step.passed:not(.active) {
  svg {
    display: unset;
  }
}

.survey-steps-bar.greeting {
  background-color: #f4f8fb;
  padding: 20px 40px;
  border-radius: 4px;

  svg {
    color: #b51452;
  }

  @media ($max-md) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &>div {
      width: 33%;
      margin-bottom: 20px;
    }
  }

  @media ($max-xs) {
    &>div {
      width: 50%;
    }
  }
}

.survey-steps-bar.navigation {
  max-width: 1000px;

  .survey-bar-step {
    cursor: pointer;
  }

  .bar-step-icon {
    &.pad-view {
      svg {
        width: 26px;
        height: 26px;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      @media ($max-md) {
        width: 20px;
        height: 20px;
      }
    }
  }

  svg {
    color: #848b92;
  }

  .bar-step-icon {
    margin-bottom: 0;
  }

  .bar-step-title {
    font-size: 14px;

    @media ($max-md) {
      font-size: 12px;
      line-height: 24px;
    }
  }

  .survey-bar-step {
    padding-bottom: 3px;
    margin-bottom: 12px;
  }

  .survey-bar-step.active {

    .bar-step-title,
    svg {
      color: #b51452;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(100% + 28px);
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #b51452;
    }
  }

  @media ($max-md) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 30px;

    &>div {
      width: 25%;
      min-width: 100px;
      margin: 20px 0;
    }

    .survey-bar-step {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .survey-bar-step.active::before {
      display: none;
    }
  }

  @media ($max-xs) {
    &>div {
      width: 50%;
    }
  }
}