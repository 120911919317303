// Media breakpoints - copied from antd grid
$max-xs: 'max-width: 480px';
$max-sm: 'max-width: 576px';
$max-md: 'max-width: 768px';
$max-lg: 'max-width: 992px';
$max-xl: 'max-width: 1200px';
$max-xxl: 'max-width: 1600px';

$xs: 'min-width: 480px';
$sm: 'min-width: 576px';
$md: 'min-width: 768px';
$lg: 'min-width: 992px';
$xl: 'min-width: 1200px';
$xxl: 'min-width: 1600px';

// List of registered z-index values
$z-index-list: (
  '1002': 1002,
  '1000': 1000,
  '100': 100,
  '1': 1,
);

$border-radius-base: 4px;
$border-color: #dadada;
$base-border: 1px solid $border-color;
