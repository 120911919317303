.ow-phone-input {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 10px;
}

.react-tel-input .form-control,
.phone-input_wrapper .react-tel-input .flag-dropdown {
  border: unset;
}
