.pad-list {
  &__item {
    background-color: #f4f8fb;
    max-width: 1000px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      width: 100%;
    }
  }

  .pad-image {
    max-width: 100%;
    max-height: 290px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      min-height: 310px;
    }
  }
}