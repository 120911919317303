.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  [class*='v1-h'] {
    font-size: 40px;
  }
}

@media ($max-lg) {
  .section-title {
    [class*='v1-h'] {
      font-size: 32px;
    }
  }
  .responsive-main-btn {
    font-size: 14px;
    line-height: 32px;
  }
}

@media ($max-sm) {
  .section-title {
    flex-direction: column;
    margin-bottom: 5px;
  }
}
