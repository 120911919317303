.pad {
  background-color: #f4f8fb;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  color: $text-content-color;

  &.not-claimed {
    .pad-main {
      filter: grayscale(100%);
      opacity: 0.4;
    }
  }

  &-status {
    position: absolute;
    top: 20px;
    right: 18px;
    font-size: 16px;
    font-weight: 500;

    .verified {
      color: #27ae60;
    }

    .not-verified {
      color: #3e464e;

      .icon {
        img {
          svg {
            fill: #3e464e;
          }
        }
      }
    }

    .loading-not-possible {
      color: #fa4b14;

      .icon {
        img {
          svg {
            fill: #fa4b14;
          }
        }
      }
    }

    .in-moderation {
      color: $orange-color;

      .icon {
        img {
          svg {
            fill: $orange-color;
          }
        }
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-right: 8px;
        max-width: 22px;
        line-height: 1;

        img {
          max-width: 100%;
        }
      }
    }
  }

  &-inner {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    max-height: 310px;
    min-height: 310px;
  }

  &-image {
    // grid-column: 1 / 5;
    // max-height: 280px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 290px;

    img {
      max-width: 100%;
      min-height: 310px;
      object-fit: cover;
    }
  }

  &-main {
    grid-column: 5 / 11;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &_title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &_meta {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      &>span {
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-of-type) {
          margin-right: 30px;
        }

        span {
          line-height: 1;
        }

        .icon {
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 16px;
          }
        }
      }
    }

    &_description {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.pad-survey {
  .survey-completed-btn {
    max-width: unset;
    min-height: 40px;
    border-color: #75797c;

    &:hover,
    &:focus {
      color: darken(#27ae60, 8%);
      border-color: #27ae60;
    }

    span.icon,
    span[role='img'] {
      margin-right: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: #424242;
    }

    display: flex;
    align-items: center;
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    margin: 10px;
    color: #3e464e;
  }
}

.pad-survey-btn {
  border-color: #75797c;
  font-weight: 600;
  min-height: 40px;
  font-size: 16px;

  &.init {
    color: #27ae60;

    &:hover,
    &:focus {
      color: darken(#27ae60, 8%);
      border-color: #27ae60;
    }
  }

  &.continue {
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 8%);
    }
  }
}

@media only screen and (max-width: 1220px) {
  .pad {
    &-status {
      font-size: 14px;
    }

    &-inner {
      max-height: 410px;
      min-height: 310px;
    }

    &-image {
      // max-height: 410px;
      max-width: 100%;
      min-height: 310px;
    }

    &-main {
      padding: 45px 40px 30px;

      &_title {
        font-size: 21px;
        margin-bottom: 15px;
      }

      &_meta {
        margin-bottom: 15px;
      }

      &_description {
        overflow: hidden;
        position: relative;
        font-size: 15px;
        max-height: 95px;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .pad {
    &-main {
      &_title {
        margin-bottom: 10px;
      }

      &_meta {
        // flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        &>span {
          justify-content: flex-start;
          padding: 3px 0;
        }
      }
    }

    &_description {
      margin-bottom: 10px;
    }
  }
}

@media ($max-md) {
  .pad {
    &-main {
      padding: 20px 20px 0px;

      &_meta {
        flex-wrap: wrap;
        width: 100%;

        &>span {
          width: 50%;
          margin-left: 0;

          &:not(:last-of-type) {
            margin-right: 0px;
          }
        }
      }
    }

    &-status {
      top: 10px;
      right: 10px;
      z-index: 10;
      background-color: $card-color;
      padding: 5px 10px;
      border-radius: 3px;
    }
  }
}

@media ($max-sm) {
  .pad {
    &-main {
      align-items: center;

      &_meta {
        text-align: center;
        align-items: center;

        &>span {
          justify-content: center;

          &:not(:last-of-type) {
            margin-right: 0px;
          }
        }

        &_description {
          text-align: center;
        }
      }
    }
  }
}