.pad-form-container {
  .pad-form {
    margin-top: 60px;
  }
}

.pad-confirm_textarea,
.pad-form-container {
  .ant-input-textarea {
    position: relative;
  }
  .ant-input-textarea-show-count::after {
    position: absolute;
    bottom: 8px;
    right: 10px;
    margin: 0;
    padding: 2px 3px;
    border-radius: 2px;
    background-color: $input-color;
  }
}

.pad-confirm_modal {
  .ant-modal-confirm-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: unset;
    margin-top: 24px;
  }
  .pad-confirm {
    &_title {
      font-style: italic;
      font-weight: 500;
    }
  }
  .ant-modal-body {
    padding: 30px 24px 24px;
  }
  .ant-modal-confirm-content {
    margin-top: 14px;
  }
  .ant-modal-confirm-content {
    margin-left: 0 !important;
  }
  .ant-btn {
    min-height: 46px;
    max-width: 140px;
  }
}
