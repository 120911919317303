[class^='custom-icon-'],
[class*='custom-icon-'] {
  display: block;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-icon-check-circle-white {
  width: 32px;
  height: 32px;
  background-image: url('../../assets/icons/check-circle-white.svg');
}

.custom-icon-dark-logo {
  width: 128px;
  height: 40px;
  background-image: url('../../assets/icons/basic-company-logo-dark-version.svg');
}

.custom-icon-dark-logo-small {
  width: 32px;
  height: 40px;
  background-image: url('../../assets/icons/basic-company-logo-dark-version-small.svg');
}

.custom-icon-white-logo-small {
  width: 32px;
  height: 40px;
  background-image: url('../../assets/icons/basic-company-logo-white-version-small.svg');
}

.custom-icon-facebook {
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/facebook.svg');
}

.custom-icon-flag-grey {
  background: url('../../assets/icons/flag-grey.svg');
}

.custom-icon-flag-primary {
  background: url('../../assets/icons/flag-primary.svg');
  background-repeat: no-repeat;
}

.custom-icon-globe {
  background: url('../../assets/icons/globe.svg');
}

.custom-icon-google {
  background: url('../../assets/icons/Google_G_Logo.svg');
}

.custom-icon-help {
  background: url('../../assets/icons/help.svg');
}

.custom-icon-instagram {
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/instagram.svg');
}

.custom-icon-lock-grey {
  background: url('../../assets/icons/lock-grey.svg');
}

.custom-icon-mail {
  background: url('../../assets/icons/mail.svg');
}

.custom-icon-mail-grey {
  background: url('../../assets/icons/mail-grey.svg');
}

.custom-icon-phone {
  background: url('../../assets/icons/phone.svg');
}

.custom-icon-twitter {
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/twitter.svg');
}

.custom-icon-twitter-filled {
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/twitter-filled.svg');
}

.custom-icon-user {
  background: url('../../assets/icons/user.svg');
}

.custom-icon-user-grey {
  background: url('../../assets/icons/user-grey.svg');
}
