.survey-layout {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.greeting {
    width: 100%;
    max-width: 1150px;
    padding: 40px 0;
  }

  &>div {
    flex: 1;
  }

  .nowrap-label .ant-col.ant-form-item-label {
    overflow: visible;

    label {
      white-space: nowrap;

      @media ($max-sm) {
        white-space: initial;
      }
    }
  }
}

.survey-loading {
  padding: 25px 0;

  &__spin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 34px;
  }
}

.survey-step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__content {
    margin-top: 40px;
    width: 100%;
    max-width: 800px;
    padding: 0 16px;

    label,
    input,
    .ant-select-selector,
    .ant-form-item-explain,
    .phone-input_wrapper .react-tel-input .form-control {
      font-size: 14px;
    }

    @media ($max-md) {
      max-width: 100%;

      .ant-select {
        max-width: unset;
      }

      label,
      input,
      .ant-select-selector,
      .ant-form-item-explain,
      .phone-input_wrapper .react-tel-input .form-control {
        font-size: 12px;
      }

      margin-bottom: 100px;
    }

    &.map {
      max-width: unset;

      .ant-form {
        max-width: 800px;
        margin: 0 auto;
      }
    }

    &.view {
      max-width: 1250px;
    }
  }

  &__header {
    width: 100%;
    padding: 16px 0;
    background-color: #f4f8fb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__btn-back {
    button {
      display: flex;
      align-items: center;
      color: $black;
      font-weight: 600;

      span svg {
        fill: $black;

        @media ($max-md) {
          width: 10px;
          height: 10px;
        }
      }

      @media ($max-md) {
        justify-content: center;
        font-size: 12px;
      }
    }
  }

  &__header-title {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    font-weight: 400;
  }

  &__text-info {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    &.mb-0 {
      margin-bottom: 0;
    }

    &>.icon {
      color: $primary-color;
      height: 18px;
    }

    & .ant-typography {
      margin-left: 10px;
      font-weight: 700;
    }

    &.start {
      justify-content: flex-start;
    }
  }

  &__actions {
    margin: 0 auto;
    max-width: 1000px;
    padding: 30px 0;
    border-top: 1px solid #dadada;

    .ant-btn.ant-btn-text {
      min-height: 56px;
    }

    @media ($max-xs) {
      flex-direction: column;

      &>button {
        max-width: inherit;
      }
    }

    @media ($max-md) {
      &>button {
        font-size: 12px;
      }
    }

    // @media ($max-sm) {
    //   &>button {
    //     margin-bottom: 20px;
    //   }
    // }
  }

  &__title {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;

    @media ($max-md) {
      font-size: 14px;
      line-height: 28px;
    }
  }

  &__description {
    max-width: 732px;
    text-align: center;
    margin: 0 auto 40px;

    p {
      font-size: 14px;
      color: #3e464e;

      @media ($max-md) {
        font-size: 12px;
      }
    }

    &>p:not(:first-of-type) {
      margin-top: 10px;
    }

    .description-primary {
      font-weight: 500;
    }
  }
}

.survey-step {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      margin-bottom: 5px;
    }
  }

  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border-color: $input-color;
    background-color: $input-color;

    &::after {
      width: 11px;
      height: 11px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $input-color;
    }

    .ant-radio-inner::after {
      transform: scale(1) translate(-50%, -50%);
    }

    &+* {
      font-weight: 500;
    }
  }

  span.ant-radio+* {
    transform: translateY(3px);
  }
}

.transparent-label .ant-form-item-label {
  opacity: 0;
  visibility: hidden;
  color: transparent;

  @media ($max-md) {
    display: none;
  }
}

.transparent-label.block-hidden-sm .ant-form-item-label {
  @media ($max-md) {
    display: block;
  }

  @media ($max-sm) {
    display: none;
  }
}


.survey-textarea {
  min-height: unset !important;
  font-size: 14px;

  @media ($max-md) {
    font-size: 12px;
  }

  textarea {
    min-height: unset !important;
    font-size: 14px;

    @media ($max-md) {
      font-size: 12px;
    }
  }

  &.min-max-100>textarea {
    min-height: 100px !important;
    max-height: 100px !important;
  }

}

.max-100>textarea {
  max-height: 100px !important;
}

.survey-checkbox-list {
  .ant-checkbox-wrapper {
    margin-bottom: 0;

    &+.ant-checkbox-wrapper {
      margin-left: 0;
    }
  }

  @media ($max-sm) {
    &>.responsive {
      flex-direction: column;
    }
  }
}

.survey-checkbox-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;

  @media ($max-md) {
    font-size: 16px;
  }
}

.ant-btn.outline-btn.survey-outline {
  max-width: 196px;
  min-height: 56px;
  font-size: 16px;
}

.survey-confirmation-modal {
  max-width: 470px;

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    border: none;
    padding-bottom: 40px;
    margin-top: 40px;
    gap: 10px;

    .ant-btn+.ant-btn {
      margin-left: 0;
    }
  }
}

.survey-confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0 0;

  svg {
    color: #b51452;
    width: 50px;
    height: 50px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    max-width: 366px;
    margin: 32px auto 0;
  }

  &.font-size-small h3 {
    font-size: 18px;
    margin-top: 16px;
  }
}

.survey-confirmation-modal.default-footer {
  .ant-modal-footer {
    flex-direction: row;
  }
}

.ant-tooltip,
.ant-select-item,
.ant-input {
  @media ($max-md) {
    font-size: 12px;
  }
}

@media(max-width: 576px) {
  .survey-checkbox-list {
    .survey-checkbox-title {
      text-align: center;
    }

    &>.responsive {
      flex-direction: column;
      align-items: flex-start;

      &>div>.ant-checkbox-wrapper {
        justify-content: flex-start;
      }
    }
  }
}